@import url(https://fonts.googleapis.com/css?family=Baloo+2&display=swap);
@import url(https://cdn.jsdelivr.net/npm/semantic-ui@2.4.2/dist/semantic.min.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* @fontface {
  font-family: 'Baloo 2', cursive;
  src: url('https://fonts.googleapis.com/css?family=Baloo+2&display=swap')
} */
.image-gallery-icon {
  color: #fff;
  transition: all .2s ease-out;
  -webkit-appearance: none;
          appearance: none;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  outline: none;
  position: absolute;
  z-index: 4;
  -webkit-filter: drop-shadow(0 2px 2px #1a1a1a);
          filter: drop-shadow(0 2px 2px #1a1a1a); }
  @media (min-width: 768px) {
    .image-gallery-icon:hover {
      color: #337ab7; }
      .image-gallery-icon:hover .image-gallery-svg {
        -webkit-transform: scale(1.1);
                transform: scale(1.1); } }
  .image-gallery-icon:focus {
    outline: 2px solid #337ab7; }

.image-gallery-using-mouse .image-gallery-icon:focus {
  outline: none; }

.image-gallery-fullscreen-button,
.image-gallery-play-button {
  bottom: 0;
  padding: 20px; }
  .image-gallery-fullscreen-button .image-gallery-svg,
  .image-gallery-play-button .image-gallery-svg {
    height: 28px;
    width: 28px; }
  @media (max-width: 768px) {
    .image-gallery-fullscreen-button,
    .image-gallery-play-button {
      padding: 15px; }
      .image-gallery-fullscreen-button .image-gallery-svg,
      .image-gallery-play-button .image-gallery-svg {
        height: 24px;
        width: 24px; } }
  @media (max-width: 480px) {
    .image-gallery-fullscreen-button,
    .image-gallery-play-button {
      padding: 10px; }
      .image-gallery-fullscreen-button .image-gallery-svg,
      .image-gallery-play-button .image-gallery-svg {
        height: 16px;
        width: 16px; } }

.image-gallery-fullscreen-button {
  right: 0; }

.image-gallery-play-button {
  left: 0; }

.image-gallery-left-nav,
.image-gallery-right-nav {
  padding: 50px 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }
  .image-gallery-left-nav .image-gallery-svg,
  .image-gallery-right-nav .image-gallery-svg {
    height: 60px;
    width: 60px; }
  @media (max-width: 768px) {
    .image-gallery-left-nav .image-gallery-svg,
    .image-gallery-right-nav .image-gallery-svg {
      height: 48px;
      width: 36px; } }
  @media (max-width: 480px) {
    .image-gallery-left-nav .image-gallery-svg,
    .image-gallery-right-nav .image-gallery-svg {
      height: 48px;
      width: 24px; } }
  .image-gallery-left-nav[disabled],
  .image-gallery-right-nav[disabled] {
    cursor: disabled;
    opacity: .6;
    pointer-events: none; }

.image-gallery-left-nav {
  left: 0; }

.image-gallery-right-nav {
  right: 0; }

.image-gallery {
  -webkit-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  position: relative; }
  .image-gallery.fullscreen-modal {
    background: #000;
    bottom: 0;
    height: 100%;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 5; }
    .image-gallery.fullscreen-modal .image-gallery-content {
      top: 50%;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%); }

.image-gallery-content {
  position: relative;
  line-height: 0;
  top: 0; }
  .image-gallery-content.fullscreen {
    background: #000; }
  .image-gallery-content .image-gallery-slide .image-gallery-image {
    max-height: calc(100vh - 80px); }
  .image-gallery-content.left .image-gallery-slide .image-gallery-image, .image-gallery-content.right .image-gallery-slide .image-gallery-image {
    max-height: 100vh; }

.image-gallery-slide-wrapper {
  position: relative; }
  .image-gallery-slide-wrapper.left, .image-gallery-slide-wrapper.right {
    display: inline-block;
    width: calc(100% - 110px); }
    @media (max-width: 768px) {
      .image-gallery-slide-wrapper.left, .image-gallery-slide-wrapper.right {
        width: calc(100% - 87px); } }
  .image-gallery-slide-wrapper.image-gallery-rtl {
    direction: rtl; }

.image-gallery-slides {
  line-height: 0;
  overflow: hidden;
  position: relative;
  white-space: nowrap;
  text-align: center; }

.image-gallery-slide {
  left: 0;
  position: absolute;
  top: 0;
  width: 100%; }
  .image-gallery-slide.center {
    position: relative; }
  .image-gallery-slide .image-gallery-image {
    width: 100%;
    object-fit: contain; }
  .image-gallery-slide .image-gallery-description {
    background: rgba(0, 0, 0, 0.4);
    bottom: 70px;
    color: #fff;
    left: 0;
    line-height: 1;
    padding: 10px 20px;
    position: absolute;
    white-space: normal; }
    @media (max-width: 768px) {
      .image-gallery-slide .image-gallery-description {
        bottom: 45px;
        font-size: .8em;
        padding: 8px 15px; } }

.image-gallery-bullets {
  bottom: 20px;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  width: 80%;
  z-index: 4; }
  .image-gallery-bullets .image-gallery-bullets-container {
    margin: 0;
    padding: 0;
    text-align: center; }
  .image-gallery-bullets .image-gallery-bullet {
    -webkit-appearance: none;
            appearance: none;
    background-color: transparent;
    border: 1px solid #fff;
    border-radius: 50%;
    box-shadow: 0 1px 0 #1a1a1a;
    cursor: pointer;
    display: inline-block;
    margin: 0 5px;
    outline: none;
    padding: 5px;
    transition: background .2s ease-out; }
    @media (max-width: 768px) {
      .image-gallery-bullets .image-gallery-bullet {
        margin: 0 3px;
        padding: 3px; } }
    @media (max-width: 480px) {
      .image-gallery-bullets .image-gallery-bullet {
        padding: 2.7px; } }
    .image-gallery-bullets .image-gallery-bullet:focus, .image-gallery-bullets .image-gallery-bullet:hover {
      background: #337ab7;
      -webkit-transform: scale(1.1);
              transform: scale(1.1); }
    .image-gallery-bullets .image-gallery-bullet.active {
      background: #fff; }

.image-gallery-thumbnails-wrapper {
  position: relative; }
  .image-gallery-thumbnails-wrapper.thumbnails-wrapper-rtl {
    direction: rtl; }
  .image-gallery-thumbnails-wrapper.left, .image-gallery-thumbnails-wrapper.right {
    display: inline-block;
    vertical-align: top;
    width: 100px; }
    @media (max-width: 768px) {
      .image-gallery-thumbnails-wrapper.left, .image-gallery-thumbnails-wrapper.right {
        width: 81px; } }
    .image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails, .image-gallery-thumbnails-wrapper.right .image-gallery-thumbnails {
      height: 100%;
      width: 100%;
      left: 0;
      padding: 0;
      position: absolute;
      top: 0; }
      .image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails .image-gallery-thumbnail, .image-gallery-thumbnails-wrapper.right .image-gallery-thumbnails .image-gallery-thumbnail {
        display: block;
        margin-right: 0;
        padding: 0; }
        .image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails .image-gallery-thumbnail + .image-gallery-thumbnail, .image-gallery-thumbnails-wrapper.right .image-gallery-thumbnails .image-gallery-thumbnail + .image-gallery-thumbnail {
          margin-left: 0;
          margin-top: 2px; }
  .image-gallery-thumbnails-wrapper.left, .image-gallery-thumbnails-wrapper.right {
    margin: 0 5px; }
    @media (max-width: 768px) {
      .image-gallery-thumbnails-wrapper.left, .image-gallery-thumbnails-wrapper.right {
        margin: 0 3px; } }

.image-gallery-thumbnails {
  overflow: hidden;
  padding: 5px 0; }
  @media (max-width: 768px) {
    .image-gallery-thumbnails {
      padding: 3px 0; } }
  .image-gallery-thumbnails .image-gallery-thumbnails-container {
    cursor: pointer;
    text-align: center;
    transition: -webkit-transform .45s ease-out;
    transition: transform .45s ease-out;
    transition: transform .45s ease-out, -webkit-transform .45s ease-out;
    white-space: nowrap; }

.image-gallery-thumbnail {
  display: inline-block;
  border: 4px solid transparent;
  transition: border .3s ease-out;
  width: 100px;
  background: transparent;
  padding: 0; }
  @media (max-width: 768px) {
    .image-gallery-thumbnail {
      border: 3px solid transparent;
      width: 81px; } }
  .image-gallery-thumbnail + .image-gallery-thumbnail {
    margin-left: 2px; }
  .image-gallery-thumbnail .image-gallery-thumbnail-inner {
    position: relative; }
  .image-gallery-thumbnail .image-gallery-thumbnail-image {
    vertical-align: middle;
    width: 100%;
    line-height: 0; }
  .image-gallery-thumbnail.active, .image-gallery-thumbnail:hover, .image-gallery-thumbnail:focus {
    outline: none;
    border: 4px solid #337ab7; }
    @media (max-width: 768px) {
      .image-gallery-thumbnail.active, .image-gallery-thumbnail:hover, .image-gallery-thumbnail:focus {
        border: 3px solid #337ab7; } }

.image-gallery-thumbnail-label {
  box-sizing: border-box;
  color: white;
  font-size: 1em;
  left: 0;
  line-height: 1em;
  padding: 5%;
  position: absolute;
  top: 50%;
  text-shadow: 1px 1px 0 black;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  white-space: normal;
  width: 100%; }
  @media (max-width: 768px) {
    .image-gallery-thumbnail-label {
      font-size: .8em;
      line-height: .8em; } }

.image-gallery-index {
  background: rgba(0, 0, 0, 0.4);
  color: #fff;
  line-height: 1;
  padding: 10px 20px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 4; }
  @media (max-width: 768px) {
    .image-gallery-index {
      font-size: .8em;
      padding: 5px 10px; } }

      .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
/* Loader (spinner) */
.lds-dual-ring {
  display: block;
  margin: 30vh auto;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 84px;
  height: 84px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #26a39c;
  border-color: #26a39c transparent #26a39c transparent;
  /* border: 6px solid #dc052d;
  border-color: #dc052d transparent #dc052d transparent; */
  -webkit-animation: lds-dual-ring 1.2s linear infinite;
          animation: lds-dual-ring 1.2s linear infinite;
}
@-webkit-keyframes lds-dual-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes lds-dual-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}




/* html { */
  /* background-color: #b4bad2;
  color: #4e4a45; */
  /* background-color: #ededed!important; */
  /* overflow-y: scroll; */
/* } */
/* Hide scrollbar for Chrome, Safari and Opera (but still be able to keep scrolling)*/
html::-webkit-scrollbar { 
  display: none;
}
@-moz-document url-prefix() { /* Disable scrollbar Firefox */
  html{
    scrollbar-width: none;
  }
}
body {
  margin: 0; /* remove default margin */
  scrollbar-width: none; /* Also needed to disable scrollbar Firefox */
  -ms-overflow-style: none;  /* Disable scrollbar IE 10+ */
  overflow-y: scroll;
  background-color: #f6f8fa;
}
body::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* Disable scrollbar Chrome/Safari/Webkit */
}
*:focus {
  outline: 0;
}
body {
  font-family: 'Baloo 2' !important;
}
html {
  /* Hide scrollbar for IE and Edge (but still be able to keep scrolling) */
  -ms-overflow-style: none;
  margin: 0;
}

#root {
  min-height: 100%;
  margin: 0 auto;
}
/* higlight icons with green on hover, 
except for icons <inside> buttons - like in panelDialog or panel components */
.MuiButtonBase-root:hover .adminPage .MuiIcon-root, 
.MuiButtonBase-root:not(.panelDialogButton):not(.panelDialogButtonDelete):not(.imagePageDownloadButton):not(.panelDialogButtonRight):not(.panelDialogButtonLong):not(.panelDialogButtonAddImageWide):hover:not(.panelDialogButtonAddImage):not(.panelButton):not(.panelDialogBar.bayern):hover
.MuiSvgIcon-root {
  color: #059a92;
  fill: #059a92;
}

/* this selector points to delete icon in adminPage table */

td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-paddingNone > div > button:nth-child(5):hover .MuiSvgIcon-root {
   fill: #ff2950ba !important;
 }
.panelDeleteIcon:hover .MuiSvgIcon-root {
  fill: #ff2950ba !important;
}

.reportingPage td.MuiTableCell-root.MuiTableCell-body:nth-child(5),
.reportingPage td.MuiTableCell-root.MuiTableCell-body:nth-child(4) {
  padding: 2px;
}
.reportingPage th.MuiTableCell-root.MuiTableCell-head:nth-child(5),
.reportingPage th.MuiTableCell-root.MuiTableCell-head:nth-child(4) {
  padding: 2px;
}
.reportingPage th.MuiTableCell-root.MuiTableCell-head:nth-child(6) {
  padding: 16px 16px 16px 60px !important;
}
/* hide horizontal scroll */
.reportingPage .MuiTablePagination-root {
  overflow-x: hidden !important;
}
.adminPage .MuiTablePagination-root {
  overflow-x: hidden !important;
}
.pageContainer {
  position: relative;
  min-height: 100vh;
  display: block;
  width: 100%;
  min-height: 100vh;
}
.contentWrap {
  clear: both;
  padding-bottom: 1.5rem;    /* Footer height */
  min-height: calc(100vh - 50px);
  width: 100%;
}

.globalFooter {
  width: 100%;
  clear: both;
  position: relative;
  height: 52px;
  margin-bottom: -50px;
}
.globalFooter.sportfive {
  background-image: -webkit-linear-gradient(top,#08364e,#094361);
}
.globalFooter.bayern {
  background-image: -webkit-linear-gradient(top,#eaeaea,#d8d5d5);
}
.globalFooter a, .loginPageFooter a {
  /* text-decoration: none; */
  color: inherit;
}
.globalPageFooterLine {
  margin-top: -21px;
  border: 0;
  height: 0;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
}
.appVersionText {
  color: #0bb5ac;
}
.userIsLoggedInAlready {
  width: 500px;
  margin: 100px auto;
  text-align: center;
}
.userAcceptConditions .item {
  line-height: 1.5 !important;
}
div, p {
  font-family: 'Baloo 2' !important;
}
.MuiDialog-root, .MuiPaper-root, .MuiTableCell-root {
  font-family: 'Baloo 2' !important;
}
.myHeader {
  width: 100%;
  height: 62px;
  background-image: -webkit-linear-gradient(top,#000, #000);
}
.myHeader.sportfive {
  background-image: -webkit-linear-gradient(top,#05223d, #05223d);
}
.myHeader.bayern {
  background-image: -webkit-linear-gradient(top, #dc052d, #dc052d);
}
.myMenu {
  float: left;
  display: inline-block;
  margin: 20px 0px 0px 30px;
}
.myContent {
  float: left;
  margin: 20px 0px 0px 30px;
  width: 930px;
  display: inline-block;
  margin-bottom: 15px;
  /* margin-bottom: 20px; */
}
.myContentClient {
  width: 82vw;
  max-width: 1200px;
  margin: 20px auto 10px;
}
.logoArea {
  position: absolute;  
  left: 0px;
}
.logoArea.sportfive {
  top: 2px;
  margin-left: 31px;
}
.logoArea.bayern {
  top: 3px;
  margin-left: 48px;
}
.logoArea.nhl {
  top: 3px;
  margin-left: 48px;
}
.logoArea.infront {
  top: 2px;
  margin-left: 50px;
}

.logoMain {
  width: auto;
  vertical-align: middle;
}
.logoMain.sportfive {
  height: 32px; 
  margin-top: 15px;
}
.logoMain.bayern {
  height: 54px; 
  margin-top: 1px;
}
.logoMain.nhl {
  height: 54px; 
  margin-top: 1px;
}
.logoMain.infront {
  height: 61px; 
  margin-top: -2px;
}
.logoVirtualAdvertising {
  /* display: none; */
  height: 44px; 
  margin-top: 6px;
  margin-left: 30px;
  /* --changes end-- */
  width: auto;
  vertical-align: middle;
  opacity: 0.95;
}
.logoVirtualAdvertising.sportfive {
  margin-top: 6px;
  margin-left: 30px;
}
.logoVirtualAdvertising.bayern {
  margin-top: 3px;
  margin-left: 40px;
  opacity: 1;
}
.logoVirtualAdvertising.nhl {
  margin-top: -1px;
  margin-left: 34px;
  opacity: 0.9;
}
.logoVirtualAdvertising.infront {
  margin-top: -1px;
  margin-left: 40px;
  opacity: 1;
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
}

.logoText {
  display: inline-block;
  padding: 21px;
  vertical-align: middle;
  font-size: 21px;
  opacity: 0.9;
  color: #eee;
  letter-spacing: 0.02rem;
	font-weight: 500;
  word-spacing: 1px;
}
.logoTextBySupponor {
  font-size: 16px;
  color: #eee;
  position: absolute;
  top: 40px;
  left: 400px;
}
.logoTextSupponor {
  color: #00d6c7;
}
.logoTextAnalytics {
  color: #9ee9f1;
}
.headerMenu {
  right: 180px !important;
  position: absolute !important;
  top: 13px !important;
}
.headerMenuItem {
  display: inline-block;
  padding: 10px !important;
  
  vertical-align: middle !important;
  font-size: 16px !important;
  color: #eee !important;
  font-weight: 200 !important;
}
.headerMenuItem:hover {
  cursor: pointer;
  color: red !important;
}
.active.item.headerMenuItem {
  color: red !important;
}
.userArea {
  position: absolute;
  top: 3px;
  right: 75px;
}
.userRole {
  font-size: 14px;
  display: inline-block;
  margin-top: 20px;
  vertical-align: bottom;
  /* color: #6fdde8; */
  color: #00d6c7;
  /* color: #fff; */
}
.profileLogo {
  width: 110px;
  padding: 5px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.profileWindow {
  /* prevents overlaying with reporting table header text and calendar dates */
  z-index: 9999;
  opacity: 1;
  background-color: white;
  position: -webkit-sticky;
  position: sticky;
}
/* prevent overlay */
.MuiTableCell-head {
  position: static!important;
  position: initial!important;
}
.profileMain {
  z-index: 99999;
  position: -webkit-sticky;
  position: sticky;
  background-color: white;
  opacity: 1;
}
.profileBlock {
  width: auto; 
  max-width: 240px;
  max-height: 310px;
  padding: 12px;
  text-align: left;
}
.iconGreenProfile {
  color: #036963;
}
.profileMenuListItem {
  padding: 15px 0px !important;
}
.profileMenuListItem:hover a.description{
  color: #ff0000b3 !important;
}
.profileMenuListItem:hover .iconGreenProfile{
  color: #ff0000b3 !important;
}
.profileButtonsBlock {
  padding-bottom: 3px;
  margin-top: -10px;
  text-align: center;
}
.profileButton {
  text-transform: none!important;
  margin-bottom: 15px!important;
  width: 180px;
}
.headerIcon.sportfive .MuiSvgIcon-root {
  fill: #c8cecf !important;
  color: #ccc !important;
}
.headerIcon.bayern .MuiSvgIcon-root {
  fill: #ddd !important;
  color: #ddd !important;
}
.headerIcon.sportfive .MuiSvgIcon-root:hover {
  fill: #fb4006 !important;
  color: #fb4006 !important;
}
.headerIcon.bayern:hover .MuiSvgIcon-root:hover {
  fill: #fff !important;
  color: #fff !important;
}

/* new Menu */
.item:hover .menuIcon {
  color: red !important;
}
.active.item .menuIcon {
  color: #ff0000b3 !important;
  /* transition: 0.2s; */
}

/* CALENDAR */
/* .MuiPaper-root {background-color: #f6f8fa!important;} */
/* leave white background in fullsize dialog window (used in calendar) */
/* .MuiPaper-root.galleryViewRightSide {background-color: #fff!important;}
.MuiPaper-root.galleryViewLeftSide {background-color: #fff!important;} */
/* .MuiPaper-root {background-color: #eef!important;} */
.MuiDialog-root .MuiDialog-paperFullScreen {
  background-color: #f6f8fa;
}
.rbc-calendar {
  text-decoration: none;
  width: 100%;
  height: 500px !important;
  height: 80%;
}
.rbc-event {
  padding: 0 !important;
  font-size: 13px !important;
}
.rbc-event-content:active, .rbc-event-content:active:focus {
    outline: 0;
    border: none;
    -moz-outline-style: none;
    outline-style: none;
}
.rbc-calendar .MuiIconButton-root:hover .MuiSvgIcon-root {
    fill: #04bfb2 !important;
    color: #04bfb2 !important;
}
.rbc-calendar .MuiSvgIcon-root {
    fill: #6e6e6e;
}
/* prevent profile-block overlay */
.rbc-row-content {
  z-index: inherit!important;
}
/* CALENDAR ENDS */

.galleryContainer {
  /* width: 70%;
  max-height: 60%; */
  width: 60%;
  float: left;
  max-width: 60vw;
  max-height: calc(100vh - 140px);
}
.allignCenter {
  text-align: center;
}
.MuiBreadcrumbs-root, .MuiBreadcrumbs-ol {
  /* color: black!important; */
  /* display: inline; */
}
.panelBlockFeedView {
  width: 100%;
  
}
.panelBlock, .leftPanelBlock {
  color: rgb(3, 3, 3);
  font-size: 16px;

  margin-bottom: 20px;
  float: left;
}
.leftPanelBlock {
  width: 10%;
  min-width: 100px;
  margin-left: 60px;
}
.panelBlock {
  width: calc(100% - 300px);
  min-width: 300px;
  margin-left: 92px;
}
.panelLabel {
  padding: 0.4rem 0.11rem !important;
  font-weight: 500 !important;
  margin-top: 5px !important;
}
/* .panelButton {
  color: white;
  background-color: #287f92 !important;
  transition: 0.6s;
  margin-bottom: 10px;
  justify-content: left;
  width: 110px;
  
}
.panelButton:hover {
  background-color: #00a1c9 !important;
  cursor: pointer;
} */
/* .addImageButton {
  width: 100%;
  height: 100%;
}
.addImageButton:hover {
  cursor: pointer;
} */
.clientUser {
  display:none;
}
/* avoid overlaying for modal window:
   Toast notifications and tooltips are visible in panelModal this way */
div.modalWindow > div > div {
  z-index: 1000!important;
}

.headerModalPanelBlock {
  margin: 20px 30px 0px 30px;
  padding-bottom: 15px;
  border-bottom: 1px solid #e4e4e5;
}
.headerModal {
  font-weight: bold;
  margin-left: 3px;
}
.dataModalPanelBlock {
  color: rgb(3, 3, 3);
  font-size: 16px;
  /* margin-left: 20px;
  margin-bottom: 20px; */
  width:100%;
}
.modalPanelBlock {
  color: rgb(3, 3, 3);
  font-size: 16px;
  margin-left: 20px;
  margin-bottom: 20px;
  float: left;
}
.modalPanelButton {
  /* padding: 3px; */
  width: 40px;
  height: 36px;
  background: #00a1c9;
  border-radius: 7px;
  cursor: pointer;
  margin-bottom: 10px;
}
.modalPanelButtonIcon {
  width: 100%; height: 100%;
  color: white;
  margin: 6px 6px 0px 8px;
   /* display: inline-block; position: absolute; left: 0px; top: 0px; opacity: 0;  */
   
}
.imageBlock .MuiSvgIcon-root {
  font-size: 14px!important;
}
.imageBlock .MuiSvgIcon-root:hover {
  fill: #00a1c9;
}
.imageIcons {
  margin-left: 5px;
}

.leftColumn, .midColumn, .rightColumn {
  display: inline-block;
  padding-left: 30px;
  vertical-align: middle;
}
.rightColumn {
  margin-left: 60px;
}
.closeModalButton {
  position: absolute;
  right: 14px;
  top: 8px;
}

.imageBlock {
  display: inline-block;
  padding: 0px 10px;
}
/* aligning delete and download icons */
.imageBlock>button {
  margin-left: 11px;
}
.s3Image {
  max-width: 120px !important;
  max-height: 200px !important;
  padding: 2px;
  display: block;
}
.s3Image:hover {
  max-width: 124px;
  padding: 2px 2px;
}
/* override react-modal-image style */
.__react_modal_image__modal_container {
  /* background-color: rgba(0, 100, 100, 0.8)!important; */
  background-color: rgba(49, 71, 150, 0.4)!important;
}
.__react_modal_image__icon_menu {
  padding-top: 5px;
}
[data-test="sign-out-button"] {
  background-color: #41769c!important;
  display: none!important;
  border: 0;
}
.image-preview {
  height: 100px;
  width: 100px;
  object-fit: cover;
  border-radius: 30%;
  text-align: center;
}
.noFiles {
  color: #777;
}
/* GALLERY */
.image-gallery-thumbnail {
  width: 120px
}
.image-gallery-thumbnail img {
  width: auto;
  height: 75px;
}
.image-gallery-fullscreen-button,
.image-gallery-play-button {
  padding: 15px 40px; 
}

/* .image-gallery-icon {
  padding: 20px 100px;
} */
/* breadcrumbs */
.MuiTypography-body1 {
  font-family: 'Baloo 2' !important;
}
/* .image-gallery-slides {
  text-align: center;
}

.image-gallery-slide {
  background: #ededed;

} 
.image-gallery-slide img {
  width: auto;
  height: 400px;
} */
.image-gallery-content .image-gallery-slide .image-gallery-image {
  min-height: 400px;
}
.imagePageRightSide {
  float: left;
  max-width: 20vw;
  min-width: 220px;
  padding: 0px 10px 10px 10px;
  margin-left: 10px;
  margin-top: -15px;
}
.imagePageMatchName {
  text-align: center;
}
/* Image upload */
.image-upload>input {
  display: none;
}

/* Admin page */
/* .MuiIconButton-root  */
.adminPage .MuiIcon-root {
  color: #3d403c8a;
}
/* .MuiButtonBase-root  */
.adminPage .MuiSvgIcon-root {
  fill: #3d403c8a;
}
.iconRed {
  color: #f14141bf !important;
  fill: #f14141bf !important;
}
.iconGreen {
  color: #00d6c7 !important;
  fill: #00d6c7 !important;
}
.adminPage .iconGreen .MuiSvgIcon-root {
  color: #00d6c7 !important;
  fill: #00d6c7 !important;
}
.adminPage .iconRed .MuiSvgIcon-root {
  color: #f14141bf !important;
  fill: #f14141bf !important;
}
.deleteCompanyIcon:hover {
  color: #ff2950ba !important;
  fill: #ff2950ba !important;
}
.MTableToolbar-actions .MuiSvgIcon-root {
  color: #ff2950ba !important;
  fill: #ff2950ba !important;
}
/* .MuiButtonBase-root .MuiSvgIcon-root:hover {
  fill: lightgreen;
} */
.adminPage .Mui-disabled .MuiIcon-root {
  color: #b6b9b6;
}

.myMenu .MuiSvgIcon-root {
  
  transition: 0.6s;
  /* fill: #3572908a; */
  fill:#08364e;
}
.MuiListItem-root:hover .MuiTypography-root {
  color:#4ca2b5;
  
}
.MuiMenuItem-root {
  padding-top: 13px !important;
}
.MuiMenuItem-root:hover .MuiButtonBase-root {
  background-color: white !important;
}
.MuiListItem-root:hover .MuiSvgIcon-root {
  fill:#4ca2b5;
  /* color: black!important; */
}
.Mui-selected .MuiSvgIcon-root {
  fill: #4ca2b5;
}
/* ///////  #03a9f4 ////////// */

.loginPage {
  /* position: absolute; */
  margin-top: 30px;
  height: 180px;
  width: 100%;
  /* top: 5px;
  left: 5px; */
}
.loginPageLeft {
  float: left;
  width: 30%;
  height: 100%;
}
.loginPageRight {
  float: right;
  width: 30%;
  height: 100%;
}

.loginPageCenter {
  /* float: left; */
  margin: 0 auto;
 
  width: 50%;
  
  /* width: "calc(100% - 620)"; */
  height: 100%;
  text-align: center;
  
}
.loginPageCenter.sportfive {
  margin-top: 7%;
  margin-bottom: -30px;
}
.loginPageCenter.bayern, .loginPageCenter.nhl {
  margin-top: 5%;
  margin-bottom: -40px;
}
.loginPageCenter.infront {
  margin-top: 7%;
  margin-bottom: -35px;
}
.loginPageCenter3 {
  margin: 0 auto;
  width: 40%;
  position: relative;
  margin-top: 30px;
  /* width: "calc(100% - 620)"; */
  /* height: 100%; */
  text-align: center;
}

.loginPageOptions {
  position: absolute;
  right: 4px;
  bottom: 64px;
  /* width: 30px;
  height: 40px; */
}
.MuiRadio-root {
  padding: 2px !important;
}
.welcomeTo {
  font-size: 12px;
  /* margin-right: 80px; */
}
.HubAlanyticsNReporting {
  /* font-weight: 600;
  
  font-size: 32px; */
  font-size: 32px;
letter-spacing: 2.8px;
word-spacing: 2px;
color: #000000;
font-weight: normal;
-webkit-text-decoration: none solid rgb(68, 68, 68);
        text-decoration: none solid rgb(68, 68, 68);
font-style: normal;
/* font-variant: small-caps; */
font-weight: 500;
text-transform: none;
}
.loginPageDescription {
  text-align: center;
  font-size: 16px;
  /* margin-top: -20px; */
}
/* .lagardereLogo {
  
  margin-left: 150px;
  margin-top: 16px;
  opacity:0.8;
  width: 140px;
}
.lagardereLogo2 {
  margin-left: 80px;
  margin-top: 16px;
  opacity:0.8;
  width: 170px;
}
.lagardereLogo3 {
  margin-top: 20px;
  margin-bottom: -20px;
}
.supponorLogo2 {
  margin-left: 160px;
  margin-top: 55px;
  width: 100px;
  opacity: 0.6;  
}
.supponorLogo {
  
  margin-left: 50px;
  margin-top: 40px;
  width: 80px;
  opacity: 0.6;
}
.loginPageButtons {
  margin-top: 20px;
}
.loginPageButtons2 {
  margin-top: 30px;
}
.loginPageButton {
  width: 70px;
  font-size: 10px !important;
  margin-left: 20px !important;
  text-transform: none;
  padding: 5px !important;
} */
.loginPageLine {
  margin-top: -25px;
  border: 0;
  height: 1px;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
  width: 60%;
}
.loginPageSportfiveLogo.sportfive {
  width: 240px;
}
.loginPageSportfiveLogo.bayern {
  width: 92px;
}
.loginPageSportfiveLogo.nhl {
  width: 92px;
}
.loginPageSportfiveLogo.infront {
  width: 220px;
  opacity: 0.9;
  margin-bottom: 12px;
}
.loginPageFooter {
  height: 22px; /* added to fix semantic ui related issues */
  width: 100%;
  position: absolute;
  bottom: 0px;
}

.loginPageFooterLine {
  /* position: absolute;
  bottom: 50px; */
  /* margin-top: -20px; */
  margin-top: -30px;
  border: 0;
  height: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}
/* remove "No account? Create account" from login form */
/* Very hacky workaround, solved with CSS trick */
/* TODO: remove it in app.js by removing or customizing
SectionFooterSecondaryContent subcomponent  */
span[class^="Section__sectionFooterSecondaryContent"] {
  visibility: hidden;
}
/* don't hide other hints that are shown inside 
"sectionFooterSecondaryContent" on various other pages */
a[data-test="verify-contact-skip-link"], 
a[data-test="forgot-password-resend-code-link"],
a[data-test="forgot-password-back-to-sign-in-link"],
a[data-test="require-new-password-back-to-sign-in-link"]
{
  visibility: visible !important;
}
.footerText {
  margin-left: 40px;
  font-size: 14px;
  display: inline-block;
  letter-spacing: 0.7px;
  word-spacing: 1.6px;
  vertical-align: middle;
  color: #999;
}
.footerText.sportfive {
  color: #999;
}
.footerText.bayern {
  color: #888;
}
.logoSupponor {
  height: 34px;
  margin-left: 40px;
  opacity: 0.9;
  /* display: inline-block; */
  vertical-align: middle;
  /* float: left; */
  /* margin-right: 80px; */
}
.red {
  color: red;
}
.greenColor {
  color: darkgreen;
  font-weight: 600;
}
.italic {
  font-style: italic;
}

/* panelDialog */
.panelDialogBar {
  /* background-image: -webkit-linear-gradient(top,#08364e,#094361); */
  margin-bottom: 50px;
}
.panelDialogBar.sportfive {
  background-color: #231f21 !important;
  opacity: 1 !important;
}
.panelDialogBar.bayern {
  background-color: #dc052d !important;
  opacity: 1 !important;
  font-family: 'Baloo 2' !important;
}
/* text color in in panelDialog top bar */
.panelDialogBar.bayern button {
  opacity: 1 !important;
  color: white;
  font-family: 'Baloo 2' !important;
  font-size: 1rem !important;
}
.panelDialogBar.sportfive button {
  opacity: 0.9 !important;
}
.panelDialogBarDivider {
  margin: 0 8px !important;
}
.sportfive .panelDialogBarDivider {
  background-color: #aaa !important;
  opacity: 0.4;
}
.bayern .panelDialogBarDivider {
  background-color: #c7354a !important;
  opacity: 0.9;
}

/* Close button in toolbar <X> */
.panelDialogBar .MuiIconButton-edgeStart {
  position: absolute;
  color: white !important;
  right: 30px;
}
/* table title */
.MuiTypography-h6 {
  font-family: 'Baloo 2' !important;
}
/* panelDialog header - match name */
.MuiDialog-paperFullScreen .MuiTypography-h6 {
  font-size: 1.1rem !important;
  font-weight: 400 !important;
  min-width: 150px;
  max-width: 400px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
}
.MuiToolbar-gutters {
  padding-left: 8px !important;
}
.panelDialogMain {
  margin-top: 90px;
  height: 100%;
  width: 100%;
}
.galleryViewPanelDialogMain {
  width: 100%;
  height: 100%;
}
.galleryViewLeftSide, .galleryViewLeftSideTransferList {
  margin-left: 30px;
  width: 60%;
  float: left;
  max-width: 60vw;
  height: calc(100vh - 140px);
  max-height: calc(100vh - 140px);
  text-align: center;
  /* vertical scroll (for multiple videos) */
  overflow-y: auto;
  overflow-x: hidden;
}
.galleryViewLeftSideTransferList {
  width: 56%;
}
.image-gallery-content 
.image-gallery-slide 
.image-gallery-image 
{
  min-height: 400px;
  max-height: 60vh;
}
/* display images in "almost" fullscreen when fullscreen-icon is pressed 
   (90% - to leave place for thumbnails below the slide */
.image-gallery-content.fullscreen .image-gallery-slide 
.image-gallery-image {
  max-height: 90vh !important;
}
.galleryViewRightSide {
  float: left;
  max-width: 32vw;
  min-width: 280px;
  padding: 10px;
  margin-left: 10px;
} 
.galleryViewRightSideTransferList {
  float: left;
  max-width: 32vw;
  min-width: 280px;
  padding: 0 10px 10px 10px;
  margin-left: 10px;
  text-align: center;
}
.galleryViewButtonsTransferListWindow {
  width: 100%;
  margin: auto;
  /* text-align: center; */
  margin-top: 15px;
}
.galleryViewMatchInfo {
  width: 100%;
}
.galleryViewButtonsSecondary {
  margin: auto;
  text-align: center;
}
.galleryViewButtons {
  margin: auto;
  text-align: center;
  margin-top: 15px;
}
.progressBar {
  margin-top: 120px;
}
.progressBarLabel {
  margin: 80px auto !important;
  width: 300px !important;
}
.progressBarLabelWithIcon {
  width: 380px !important;
  margin: auto !important;
  margin-bottom: 40px !important;
}
.dividerText {
  font-size: 10px;
  font-weight: 200;
}
.lowOpacityIcon {
  opacity: 0.7 !important;
}
.panelDialogButton, .panelDialogButtonDelete, .panelDialogButtonRight, .imagePageDownloadButton,
.panelButton, .panelDialogButtonLong, .panelDialogButtonAddImage, .panelDialogButtonAddImageWide {
  color: white !important;
  background-color: #204173 !important;
  opacity: 0.8;
  transition: 0.6s;
  margin-bottom: 5px !important;
  justify-content: left;
  font-size: 0.75rem !important;
  padding: 6px 10px !important;
  width: 110px !important;
  text-transform: none !important;
}
/* fix "Feed images" button in Safari */
.panelDialogButton {
  width: 120px !important;
}
.panelButtonDisabled {
  background-color: #a4c1ec !important;
}
.ui.small.action.input {
  margin-right: 52px;
  width: 130px;
}
.messageToClients {
  margin-left: 20px !important;
  width: 260px !important;
}
.ui.icon.button {
  width: 12px;
}
.ui.tag.label {
  cursor: default;
}

.videoNavigationButton {
  width: 100px !important;
}
.videoNavigationButton:hover {
  color: #ed1c24 !important;
  background-color: #ddd !important;
}

.widerButton {
  width: 160px !important;
  font-size: 0.8rem !important;
}
.confirmVideoButton {
  width: 136px !important;
  font-size: 0.8rem !important;
}
.confirmVideoButton.greenColor {
  margin-right: 40px;
  background-color: #00b5ad!important;
  color: #fff!important;
}
.panelDialogButtonLong {
  width: 190px !important;
}
.panelDialogButtonAddImage {
  background-color: #287f92 !important;
}
.panelDialogButtonAddImageWide {
  background-color: #287f92 !important;
  min-width: 252px !important;
}
.panelDialogButton:hover, .panelDialogButtonDelete:hover, 
.panelDialogButtonRight:hover, .imagePageDownloadButton:hover,
.panelButton:hover, .panelDialogButtonLong:hover,
.panelDialogButtonAddImageWide:hover {
  opacity: 1;
  cursor: pointer;
}
.panelDialogButtonDelete, .panelDialogButtonRight {
  margin-left: 10px !important;
}
.panelDialogButtonDelete, .redBackgroundButton {
  background-color: #c7354a !important;
}
.panelDialogFeedTableCell {
  color: #139685 !important;
  font-weight: 600 !important;
}
.panelButton {
  font-size: 0.8rem !important;
  width: 105px !important;
}
.imagePageDownloadButton {
  width: 160px !important;
}

.panelDialogSwitch {
  z-index: 100000;
  display: inline-block; 
}
.dividerComponent {
  margin: 10px 0px;
}
.noImages {
  margin-top: 200px;
  margin-bottom: 200px;
  font-size: 20px;
  text-align: center;
}

.videoContainer {
  width: 100%; 
}

/* panelDialog ends */

/* MEDIA QUARIES */
/* @media only screen and (min-width: 640p) and (max-width: 1201px) {
  body {
    background-color: black;
  }
  .myContent {
    width: 700px;
  }
} */
@media only screen and (max-width: 4000px) {
  .myMenu {
    margin-left: 120px;
  }
  .myContent {
    width: 1250px;
    margin-left: 140px;
  }
}

/* 20% users have such resolution  */
@media only screen and (max-width: 1920px) {
  .myMenu {
    margin-left: 100px;
  }
  .myContent {
    width: 1250px;
    margin-left: 100px;
  }
}
/* 1600 - 4%, 1536 - 8,6% */
@media only screen and (max-width: 1600px) {
  .myMenu {
    margin-left: 60px;
  }
  .myContent {
    width: 1150px;
    margin-left: 60px;
  }
}

@media only screen and (max-width: 1536px) {
  .myMenu {
    margin-left: 60px;
  }
  .myContent {
    width: 1100px;
    margin-left: 60px;
  }
}

/* 7,3% */
@media only screen and (max-width: 1440px) {
  .myMenu {
    margin-left: 50px;
  }
  .myContent {
    width: 1060px;
    margin-left: 20px;
  }
}
/* 1366 - 24% */
@media only screen and (max-width: 1366px) {
  .myMenu {
    margin-left: 50px;
  }
  .myContent {
    width: 960px;
    margin-left: 80px;
  }
}

/*  1280 - 5% */
@media only screen and (max-width: 1280px) {

  .myContent {
    width: 960px;
    margin-left: 46px;
  }
}

/*  1280 - 5% */
@media only screen and (max-width: 1200px) {

  .myContent {
    width: 820px;
  }
}

@media only screen and (max-width: 1080px) {
  .myMenu, .myContent {
    margin-left: 12px;
  }
  .myMenu {
    width: 13%;
  }
  .myContent {
    width: 84%;
  }
}
@media only screen and (max-width: 963px) {
  .myMenu, .myContent {
    margin-left: 12px;
  }
  .myMenu {
    width: 13%;
  }
  .myContent {
    width: 84%;
  }
}
@media only screen and (max-width: 880px) {
  .myMenu, .myContent {
    margin-left: 10px;
  }
  .myMenu {
    width: 14%;
  }
  .myContent {
    width: 83%;
  }
}
@media only screen and (max-width: 800px) {
  .myMenu, .myContent {
    margin-left: 10px;
  }
  .myMenu {
    width: 16%;
  }
  .myContent {
    width: 80%;
    /* float: right; */
  }
}
@media only screen and (max-width: 720px) {
  body {
    /* background-color: green; */
  }
  .myMenu, .myContent {
    margin-left: 5px;
  }
  .myMenu {
    width: 20%;
  }
  .myContent {
    width: 78%;
  }
 .globalFooter {
    display: none;
  }
}
@media only screen and (max-width: 640px) {
  .myMenu, .myContent {
    margin: 0;
  }
  .myMenu {
    width: 24%;
  }
  .myContent {
    width: 75%;
  }
  .loginPageFooter, .logoText, .globalFooter {
    display: none;
  }
  .loginPageCenter {
    margin-top: 10px;
  }
}
@media only screen and (max-height: 640px) {
  .loginPageFooter, .logoText, .globalFooter {
    display: none;
  }
  .loginPageCenter {
    margin-top: 10px;
  }
}

/* semantic ui styles */
.ui.vertical.menu .item>i.icon {
  color: #08364e;
  transition: 0.6s;
  opacity: 0.9 !important;
}

.customizedSegment {
  padding: 5px !important;
  background-color: #fff !important;
}
.customizedSegmentCalendarPage {
  padding: 0px !important;
  background-color: #fff !important;
}

/* semantic ui fixes */
*, :after, :before {
  box-sizing: content-box;
  box-sizing: initial;
}
.footerText {
 padding-top: 10px;
}
html {
  height: auto !important; 
  /* fixes page height in login page */
}

/* modal window fix (these styles mostly instead of "inherit" */

.MuiDialogActions-root .MuiButton-label {
  justify-content: flex-end;
}
.MuiDialogActions-root .MuiButtonBase-root {
  text-transform: uppercase;
  min-width: 64px;
  width: auto;
}

/* optional semantic ui fixes */

.MuiTableRow-footer .MuiTableCell-root {
  border-bottom: 0px;
}
.MuiTableCell-root {
  font-size: 1rem !important;
  /* padding: 16px 10px !important; */
}
.panelDialogMain .MuiTableCell-root, .imagesPage .MuiTableCell-root {
  font-size: 0.875rem !important;
  /* padding: 16px 10px !important; */
}
/* #c0caf97d */
/* #e1e9f0 - alternative background-color */

.videoPlayerContainer {
  /* width: 550px !important;
  height: 0px !important; */
  margin-bottom: 30px;
}
.videoTag {
  width: 100%;
  height: auto;
  margin-bottom: 30px;
}

/* popover START */
.visibleToListItem {
  padding: 0 !important;
}
.middleSizeList .visibleToListItem .MuiListItemText-primary {
  font-size: 14px;
}
.middleSizeList .visibleToListItem .MuiListItemText-root {
  margin-top: 0px;
  margin-bottom: 0px;
}
.longList .visibleToListItem .MuiListItemText-primary {
  font-size: 12px;
}
.longList .visibleToListItem .MuiListItemText-root {
  margin-top: 0px;
  margin-bottom: -3px;
}
.visibleToListSubheader {
  line-height: 24px !important;
}
.visibleToList {
  padding-bottom: 0 !important;
  /* padding-top: 0 !important; */
} 
/* popover END */

/* .MuiListItemText-primary {
  font-size: 12px !important;
} */
.reportingTableIcon {
  color: #3d403c8a; 
  fill: #3d403c8a;
}
.reportingTableIcon:hover {
  fill: #3d403c8a; 
}
.MuiTableRow-hover:hover {
  color: #ff0000b3;
}
.companySelect {
  width: 20% !important;
  min-width: 180px !important;
}
.emailInvitation {
  width: 55% !important;
  margin-right: 30px !important;
}
.modalAddUserControls {
  width: 100%;
}
.companyCellIcon {
  font-size: 1rem !important;
}
.transferListCheckBoxIcon {
  color: #059a92 !important;
  fill: #059a92 !important;
}
.numberOfCompanies .noCompanies {
  font-size: 0.8rem;
  color: #059a92;
}
.noCompanies {
  color: red;
}
.MuiTab-root:hover {
  opacity: 1 !important;
}
.sportfive .MuiTab-root:hover {
  color: #ed1c24 !important;
}

.bayern .MuiTab-root:hover {
  background-color: #e98084cc;
  color: white !important;
}
.MuiTab-root:hover .MuiSvgIcon-root {
  color: #ed1c24 !important;
  fill: #ed1c24 !important;
  opacity: 1 !important;
}
.sportfive .MuiTab-root.activeTab {
color: #ed1c24;
font-weight: 800;
letter-spacing: 0.9px;
opacity: 1 !important;
}
.bayern .MuiTab-root.activeTab {
  color: white;
  font-weight: 500;
  background-color: #e98084cc;
  opacity: 1 !important;
}
.MuiTab-root {
  min-width: 94px !important;
}
.tableFeedButtons .MuiButton-root {
  margin-left: 2px;
  text-transform: none;
}
.tableFeedButtons .MuiButton-root:hover {
  color: #ff0000b3;
}

/* tabs in Panel */

.tabrow {
  text-align: center;
  list-style: none;
  margin: 0px 0 20px;
  padding: 0;
  line-height: 35px;
  height: 37px;
  overflow: hidden;
  font-size: 12px;
  position: relative;
}

.tabrow li {
  border: 1px solid #AAA;
  background: #cce0fb;
  background: linear-gradient(top, #ECECEC 50%, #D1D1D1 100%);
  display: inline-block;
  position:relative;
  z-index: 0;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4), inset 0 1px 0 #FFF;
  text-shadow: 0 1px #FFF;
  margin: 0 -5px;
  padding: 0 30px;
}
.tabrow li:hover {
  cursor: pointer;
}
.tabrow li.selected {
  background: #FFF;
  color: #333;
  z-index: 2;
  border-top-color: #FFF;
}

.tabrow:before {
  position: absolute;
  content: "";
  width: 100%;
  top: 0;
  left: 0;
  border-top: 1px solid #AAA;
  z-index: 1;
}

.tabrow li:before,
.tabrow li:after {
  border: 1px solid #AAA;
  position: absolute;
  top: -1px;
  width: 6px;
  height: 6px;
  content: "";
}

.tabrow li:before {
  left: -7px;
  border-top-right-radius: 6px;
  border-width: 1px 1px 0px 0px;
  box-shadow: 2px 0px 0 #ECECEC;
}

.tabrow li:after {
  right: -7px;
  border-top-left-radius: 6px;
  border-width: 1px 0px 0px 1px;
  box-shadow: -2px 0px 0 #ECECEC;
}

.tabrow li.selected:before {
  box-shadow: 2px 0px 0 #FFF;
}

.tabrow li.selected:after {
  box-shadow: -2px 0px 0 #FFF;
}
/* info box in transfer list window */
/* .ui.info.message  */
.noVideosImagesMessage {
  width: 360px !important;
  /* top, right-left, bottom margins */
  margin: 150px auto 0px !important;  
}
.noVideosImagesMessage .icon {
  opacity: 0.6 !important;
}
.myDropdown {
  background-color: rgb(68, 68, 68) !important;
}

.videoPageLeftSide {
  text-align: center;
}
.underlinedV {
  text-decoration: none; 
    box-shadow:
    inset 0 -0.075em white,
    inset 0 -0.15em #276f86;
}
.panelNewRight, .panelNewLeft {
  margin-top: 60px !important;
  margin-bottom: 40px !important;
}
.panelNewCenter {
  margin-top: 32px !important;
  margin-bottom: 40px !important;
}
.panelNewRight, .panelNewCenter {
  text-align: center;
}
.panelNewLeft {
  margin-left: 15px !important;
}

.panelNewGallery {
  text-align: center;
  margin: 0px auto 40px !important
}
.panelNewVideoBlock {
  min-width: 686px;
  text-align: center;
  margin: 0px auto 40px !important
}

/* CHECK THIS ON OTHER PAGES!!!! NEED TO ISOLATE TO panelNew! */
.panelNewGallery .image-gallery-content 
.image-gallery-slide 
.image-gallery-image 
{
  min-height: 300px;
  max-height: 60vh;
}
.panelNewMessage {
  width: 420px;
  margin: 80px auto !important;
}
.minuteAllocationMessage {
  width: 308px;
  margin: 20px auto !important;
}
.messageToClientsPanelNew {
  width: 82% !important;
  max-height: 140px;
  overflow: auto;
  margin-top: -60px !important;
  margin-bottom: 20px !important;
  padding: 0px;
}
.minuteAllocationMessageText {
  width: 100%;
  text-align: center;
}
.notificationMessage {
  position: absolute !important;
}
.messageInfoIcon, .messageInfoText, .minuteAllocationMessageText {
  display: inline-block;
  vertical-align: middle;
}
.messageInfoText {
  width: 88%;
  margin-left: 2%;
  line-height: 2;
  text-align: left;
}
.dividerTextPanelNew {
  text-transform: none;
  font-weight: normal;
}
.dividerTextPanelNewVideo {
  font-weight: 200;
}
/* avoid moving of the content on zip-downoald buttons hover */
.tablePanel {
  width: 99%;
  min-height: 450px;
  max-width: 79vw;
}
/* stick to the left side the triangle for opening detail panel */
.MuiTableCell-paddingNone {
  text-align: left !important;
}
.panelNewFeedTabs .MuiTab-root{
  text-transform: none;
}
.panelNewFeedTabs {
  margin-bottom: 12px;
}
.panelNewLabel {
  margin-bottom: 10px;
  font-size: 12px;
  color: #777;
  letter-spacing: 0.5px;
  display: block;
}
.panelNewButtonsSegment {
  width: 150px !important;
  height: 94px !important;
  min-height: 94px !important;
  margin: 10px auto !important;
  text-align: left;
}
.clientDownloadButton {
  width: 140px !important;
  margin: auto !important;
}
.enterCompanyNameToDelete {
  width: 300px !important;
  margin-bottom: 10px !important;
}
.panelNewPagination {
  width: auto !important;
}
.panelNewPagination .item {
  min-width: 1em !important;
}
.panelNewVideoPaginationBlock {
  width: 100%;
  text-align: center;
  position: relative;
}
.videoControlsButton {
  width: 98px !important;
}
.videoControlsButtonFullSize {
  width: 98px !important;
  background-color: #287f92 !important;
  margin-right: 12px !important;
}
.videoControlsButtonFullSizeNotConfirmed {
  width: 120px !important;
  text-transform: none !important;
  font-size: 11px !important;
  background-color: #e1e1e1 !important;
  margin-right: 12px !important;
  color: #191c20 !important;
}
.videoControlsButtonFullSizeNotVisible {
  width: 98px !important;
  font-size: 11px !important;
  background-color: #e1e1e1 !important;
  margin-right: 12px !important;
  color: #191c20 !important;
}
.videoControlsButtonFullSizeVisible {
  width: 106px !important;
  font-size: 11px !important;
  background-color: #00b5ad !important;
  margin-right: 12px !important;
  color: white !important;
}
.panelDialogButtonTextFullLength {
  font-weight: 600;
  margin-top: 2px;
  font-family: 'Baloo 2' !important;
}
.panelNewFullmatchMode {
  background-color: #204173 !important;
}
.assignToClientsDropdown {
  width: 300px !important;
  margin: 0px auto 15px;
}
.assignToClientsDropdownMessage {
  width: 430px !important;
  margin: 0px auto 15px;
}
.watermarkText {
  position: absolute;
  top: 35px;
  right: 30px;
  color: white;
  font-size: 18px;
  opacity: 0.8;
}
.panelDialogVideoControlButtons {
  margin: 10px 0px;
}
.nonFullscreenVideo {
  width: 640px;
  margin: auto;
}
.fullscreenVideo {
  width: 100%;
}
.nonFullscreenVideo .vjs-watermark-content.vjs-watermark-top-right {
  right: 14px !important;
  top: 18px !important;
  opacity: 0.60 !important;
}
.nonFullscreenVideo .vjs-watermark-content.vjs-watermark-top-right img {
  width: 210px;
}

.fullscreenVideo .vjs-watermark-content.vjs-watermark-top-right {
  right: 34px !important;
  top: 122px !important;
  opacity: 0.60 !important;
}
.fullscreenVideo .vjs-watermark-content.vjs-watermark-top-right img {
  width: 480px;
}

/* hide picture in picture control button */
.vjs-picture-in-picture-control.vjs-control.vjs-button {
  display: none;
}

/* make poster "fit" start player screen 
https://stackoverflow.com/questions/18229974/video-js-poster-image-cover-contain 
*/
video[poster]{
  /* object-fit: cover !important; */
  
}
.vjs-poster {
  background-size: cover !important;
  opacity: 0.7;
  /* background-position: inherit !important; */
}

/* hide sound/mute button in video.js player */
.video-js .vjs-mute-control, .video-js .vjs-volume-menu-button {
  display: none;
}

.datepickerDiv {
  display: inline-flex;
}
.infoIcon {
  display: inline !important;
}
audio::-webkit-media-controls-timeline,
video::-webkit-media-controls-timeline {
    display: none;
}
/* hide vertical scroll on material table (in client view) 
   when expandable row in the bottom is opened */
div.myContentClient > div > div > div > div > div {
  overflow-y: hidden !important;
}
.appBarLogoArea {
  display: flex;
}
.appBarLogo {
  height: 36px;
  border-radius: 10%; 
  opacity: 0.8;
  align-self: center;
}
.appBarLogoText {
  margin-left: 12px;
  align-self: center;
}
.settingsPageTextArea {
  width: 88%;
}
.messageToClientsButton {
  width: 84px !important;
  margin-left: 32px !important;
}
.panelDialogButtonText {
  text-transform: none;
}
.ui.basic.button {
  margin-left: 30px;
} 
